<template>
  <!--  <v-container class="line-item-detail-new"> -->
  <v-row>
    <v-col
      md="12"
      style="margin-top: -4px !important; margin-bottom: -13px !important"
    >
      <v-layout class="justify-end">
        <v-spacer></v-spacer>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              v-bind="attrs"
              v-on="on"
              class="ml-2 custom-bold-button white--text"
              color="cyan"
              tile
              :disabled="pageLoading"
              :loading="pageLoading"
              v-on:click="getDeliveryOrderForms"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Reload</span>
        </v-tooltip>
      </v-layout>
    </v-col>
    <v-col md="12">
      <v-skeleton-loader
        type="text@5"
        class="custom-skeleton table-rows-text"
        v-if="pageLoading"
      ></v-skeleton-loader>

      <v-simple-table fixed-header height="400px" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="simple-table-th" width="10%">#</th>
              <th class="simple-table-th" width="10%">Type</th>
              <th class="simple-table-th" width="10%">Date</th>
              <th class="simple-table-th">Drivers</th>
              <th class="simple-table-th" width="15%">Total Rejected</th>
              <th class="simple-table-th" width="15%">Total Delivered</th>

              <th class="simple-table-th" width="5%">PDF</th>
              <!--  <td class="text-left">&nbsp;&nbsp;&nbsp;Balance</td> -->
            </tr>
          </thead>
          <tbody>
            <template v-if="deliveryForms && deliveryForms.length > 0">
              <template v-for="(row, index) in deliveryForms">
                <tr
                  :key="index"
                  class="line-item-listing-row"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td valign="top" class="text-left" width="10%">
                    <v-chip
                      v-if="row && row.barcode"
                      small
                      label
                      class="custom-status font-weight-600 custom-grey-border text-uppercase"
                      text-color=""
                      outlined
                      color="orange"
                    >
                      {{ row.barcode }}
                    </v-chip>
                  </td>
                  <td valign="top" class="text-left" width="10%">
                    <v-chip
                      v-if="row?.form_type == 'exchange'"
                      label
                      :color="'red'"
                      small
                      outlined
                    >
                      Exchange Note
                    </v-chip>
                    <v-chip
                      v-if="row?.form_type == 'deliver'"
                      label
                      :color="'red'"
                      small
                      outlined
                    >
                      Delivery Order
                    </v-chip>
                    <v-chip
                      v-if="row?.form_type == 'return'"
                      label
                      :color="'red'"
                      small
                      outlined
                    >
                      Return Order
                    </v-chip>
                  </td>
                  <td valign="top" class="text-left" width="10%">
                    <v-chip
                      small
                      label
                      class="custom-status font-weight-600 custom-grey-border text-uppercase"
                      text-color=""
                      color="green"
                      outlined
                    >
                      {{ formatDate(row.added_at) }}
                    </v-chip>
                  </td>
                  <td valign="top" class="text-left">
                    <template v-if="row?.drivers?.length">
                      <template v-for="(crow, CIndex) in row.drivers">
                        <v-chip
                          class="mb-1 mr-1"
                          :key="CIndex"
                          v-if="crow && crow.engineer"
                          ><v-avatar left small>
                            <img
                              v-if="
                                crow &&
                                crow.engineer.profile_logo &&
                                crow.engineer.profile_logo.file
                              "
                              :src="crow.engineer.profile_logo.file.url"
                            />
                            <img
                              v-else
                              src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                            />
                          </v-avatar>
                          <span>{{ crow.engineer.display_name }}</span>
                        </v-chip>
                      </template>
                    </template>
                    <em v-else class="text-muted"> no drivers</em>
                  </td>
                  <td valign="top" class="text-left" width="15%">
                    <template v-if="row.form_type != 'exchange'">
                      <v-avatar color="red" size="25" class="font-size-16">
                        <span class="white--text">
                          <template v-if="parentDetail?.status == 2">
                            {{ totalQuantity }}
                          </template>
                          <template v-else> 0 </template>
                        </span>
                      </v-avatar>
                    </template>
                    <template v-else>
                      <span class="font-weight-600 font-size-14"> N.A</span>
                    </template>
                  </td>
                  <td valign="top" class="text-left" width="15%">
                    <template v-if="row.form_type != 'exchange'">
                      <v-avatar color="green" size="25" class="font-size-16">
                        <span class="white--text">
                          <template v-if="parentDetail?.status == 7">
                            {{ totalQuantity }}
                          </template>
                          <template v-else> 0 </template>
                        </span>
                      </v-avatar>
                    </template>
                    <template v-else>
                      <span class="font-weight-600 font-size-14"> N.A</span>
                    </template>
                  </td>
                  <td valign="top" class="text-left" width="5%">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!row.pdf_url"
                          color="red"
                          v-on:click.stop.prevent="downloadFile(row.pdf_url)"
                          >mdi-file-pdf</v-icon
                        >
                      </template>
                      <span>Download PDF</span>
                    </v-tooltip>

                    <!--  <p class="m-0 p-2 custom-grey-border">
                                    {{ line_item.pending_qty }}
                                </p> -->
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td :colspan="10" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no delivery at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import { GET, QUERY } from "@/core/services/store/request.module";

import CommonMixin from "@/core/plugins/common-mixin";
import { DeliveryEventBus } from "@/core/lib/delivery.lib.js";
export default {
  mixins: [CommonMixin],
  props: {
    parentDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      deliveryForms: [],
      pageLoading: false,
      db_line_items: [],
      deliveryDetailData: {},
    };
  },
  methods: {
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },

    getDeliveryOrderForms() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, {
          url: "delivery/delivery-order-form/" + this.parentDetail.id,
        })
        .then(({ data }) => {
          this.deliveryForms = data;
          this.get_line_items();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    get_line_items() {
      this.loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `detail-iteam/${this.parentDetail.id}/line-items`,
          data: {
            type: 10,
          },
        })
        .then(({ data }) => {
          this.db_line_items = data;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.parentDetail.id > 0) {
      this.getDeliveryOrderForms();
    }
    DeliveryEventBus.$on("update:delivery", () => {
      this.getDeliveryOrderForms();
    });
  },

  components: {},

  computed: {
    totalQuantity() {
      return this.db_line_items.reduce((sum, item) => sum + item.quantity, 0);
    },
  },
};
</script>
